import React from 'react';
import { Grid } from '@material-ui/core';

import Typography from 'components/Typography';
import TextField from 'components/TextField';
import Icon from 'components/Icon';
import Select from 'components/Select';
import TextArea from 'components/TextArea';
import SearchableSelect from 'components/SearchableSelect';
import {
  ConfigurationFieldProps,
  ConfigurationSearchableSelectProps,
  ConfigurationSelectProps,
  ConfigurationTextAreaProps,
} from '../types';

import useStyles from '../useStyles';

export const ConfigurationField = ({
  label,
  value,
  onChange,
  placeholder,
  id,
  error,
  required,
}: ConfigurationFieldProps) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Typography>
        {required ? label : `${label} (Optional)`} <Icon icon="inst-info" width={12} height={12} />
      </Typography>
      <TextField
        value={value}
        onChange={onChange}
        fullWidth
        required
        size="small"
        variant="outlined"
        placeholder={placeholder}
        id={id}
        error={error}
        helperText={error && 'Required Field *'}
        className={classes.textField}
      />
    </Grid>
  );
};

export const ConfigurationSelect = ({ label, value, onChange, options, name, id, error }: ConfigurationSelectProps) => {
  return (
    <Grid item xs={12}>
      <Typography>
        {label} <Icon icon="inst-info" width={12} height={12} />
      </Typography>
      <Select
        value={value}
        options={options}
        onChange={onChange}
        fullWidth
        name={name}
        data-node={id}
        error={error}
        helperText={error ? 'Required Field *' : ''}
      />
    </Grid>
  );
};

export const ConfigurationTextArea = ({
  label,
  value,
  onChange,
  placeholder,
  error,
  required,
  id,
  helperText,
}: ConfigurationTextAreaProps) => {
  return (
    <Grid item xs={12}>
      <Typography>
        {required ? label : `${label} (Optional)`} <Icon icon="inst-info" width={12} height={12} />
      </Typography>
      <TextArea
        value={value}
        onChange={onChange}
        rows={4}
        fullWidth
        placeholder={placeholder}
        id={id}
        error={error}
        helperText={error && helperText ? helperText : 'Required Field *'}
      />
    </Grid>
  );
};

export const ConfigurationSearchableSelect = ({
  label,
  value,
  onChange,
  options,
  placeholder,
  id,
}: ConfigurationSearchableSelectProps) => {
  return (
    <Grid item xs={12}>
      <Typography>
        {`${label} (Optional)`} <Icon icon="inst-info" width={12} height={12} />
      </Typography>
      <SearchableSelect options={options} placeholder={placeholder} value={value} onChange={onChange} data-node={id} />
    </Grid>
  );
};
