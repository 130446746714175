import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  modalContent: {
    width: 500,
  },
  textField: {
    '& p': {
      marginLeft: 0,
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 15,
    alignItems: 'center',
  },
  addConfigBtn: {
    minWidth: 70,
    padding: '7px, 10x',
  },
  closeBtn: {
    textDecoration: 'none',
    alignSelf: 'center',
    fontSize: 16,
    marginRight: 10,
    minWidth: 70,
    border: `1px solid ${theme.palette.gray10}`,
    color: theme.palette.black,
  },
}));
